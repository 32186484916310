<template>
  <div class="page">
    <!-- Header -->
    <h2>{{ $lang.settingsPage_SeasonRollover }}</h2>
    <p>{{ $lang.settingsPage_SeasonRolloverInstructions }}</p>

    <div class="form-selection">
      <div class="form-group">
        <label for="month">{{ $lang.general_Month }}</label>
        <common-dropdown
          id="month"
          :options="monthOptions"
          size="md"
          v-model="changeOverMonth" />
      </div>
      <div class="form-group">
        <label for="day">{{ $lang.general_Day }}</label>
        <common-dropdown
          id="day"
          :options="dayOptions"
          size="md"
          v-model="changeOverDay" />
      </div>
    </div>

    <common-button
      variant="primary"
      class="btn-save"
      @click="saveChangeOverDate">
      {{ $lang.general_Save }}
    </common-button>
  </div>
</template>

<script>
  import { SET_BRANDING } from "@/store/actions.type";
  import dateService from "@/services/date.service";

  export default {
    props: ["branding"],
    data() {
      return {
        options: [],
        monthOptions: [],
        dayOptions: [],
        changeOverMonth: "",
        changeOverDay: "",
      };
    },
    created() {
      // create options for months and days
      this.options = Array.from({ length: 31 }, (_, i) => {
        const num = (i + 1).toString();
        return {
          label: num,
          value: num.padStart(2, "0"),
        };
      });
      this.monthOptions = this.options.slice(0, 12);

      [this.changeOverMonth, this.changeOverDay] = this.branding.changeOverDate;
    },
    watch: {
      changeOverMonth(val) {
        const days = dateService.daysInMonth(val);
        this.dayOptions = this.options.slice(0, days);
        if (this.changeOverDay > days) {
          this.changeOverDay = this.dayOptions[days - 1].value;
        }
      },
    },
    methods: {
      saveChangeOverDate() {
        const response = this.$store.dispatch(SET_BRANDING, {
          changeOverDate: [this.changeOverMonth, this.changeOverDay],
        });
        if (response) {
          this.$toasted.show(
            this.$lang.settingsPage_SeasonRolloverSaveSuccess,
            this.$toastedSuccess
          );
        } else {
          this.$toasted.show(
            this.$lang.general_ToastError,
            this.$toastedSuccess
          );
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  * {
    font-size: 14px;
  }
  h2 {
    color: $primary-text;
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 1.5rem;
  }
  p {
    padding-bottom: 1rem;
  }
  label {
    display: inline-block;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }

  .page {
    color: $primary-text;
    max-width: 600px;
    padding-bottom: 1rem;
  }
  .form-selection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem 0;
  }
  .btn-save {
    width: 10rem;
    margin: 2rem 0;
  }
</style>
